

const myApps = [
    {
        name: "Codez",
        description: "Simple store for access codes",
        link: "https://github.com/JoeKnowles-Jr/codez",
        ico: "codez",
        github: true
    },
    {
        name: "jk_dot_com",
        description: "This portfolio app",
        link: "https://joeknowles.com"
    },
    {
        name: "Avail",
        description: "App to coordinate availability of group members",
        link: "https://joeknowles.com/avail",
        ico: "avail"
    },
    {
        name: "Follow My Senator",
        description: "App for tracking politicians",
        link: "https://followmysenator.com",
        ico: "fms"
    },
    {
        name: "Channel",
        description: "Clone of a individual YouTube channel",
        link: "https://joeknowles.com/channel",
        ico: "channel"
    },
    {
        name: "File Depot",
        description: "File sharing app",
        link: "https://joeknowles.com/files",
        ico: "files"
    },
    {
        name: "JkBugs",
        description: "Project issue tracker",
        link: "https://joeknowles.com/jkbugs",
        ico: "jkbugs"
    }
]

const myJobs = [
    {
        title: "Driver",
        employer: "Amazon Flex",
        location: "Dallas-Ft Worth, TX",
        dates: "2016 - Present"
    },
    {
        title: "Shopper",
        employer: "Shipt",
        location: "Dallas-Ft Worth, TX",
        dates: "2016 - Present"
    },
    {
        title: "Dasher",
        employer: "Door Dash",
        location: "Dallas-Ft Worth, TX",
        dates: "2018"
    },
    {
        title: "Shopper",
        employer: "Instacart",
        location: "Dallas-Ft Worth, TX",
        dates: "2018"
    },
    {
        title: "Cook",
        employer: "Sonic",
        location: "McKinney, TX",
        dates: "2016 - Present"
    },
    {
        title: "Personal sabbatical to care for family",
        employer: "",
        location: "",
        dates: "2004 - 2015"
    },
    {
        title: "Driver",
        employer: "Driving Force, Inc.",
        location: "Baltimore, MD",
        dates: "1999 - 2003"
    },
    {
        title: "Manager",
        employer: "Bagels by the Bay",
        location: "Baltimore, MD",
        dates: "1994 - 1997"
    }
]

module.exports = {
    apps: myApps,
    jobs: myJobs
}