import React, { PureComponent } from 'react';
import { Field, reduxForm } from 'redux-form';
import styled from 'styled-components';
import * as actions from '../../actions/user.actions';
import { connect } from 'react-redux';
import history from '../../history';

class Login extends PureComponent {

    handleFormSubmit({ email, password }) {
        this.props.signinUser({ email, password });
    }

    renderError() {
        if (this.props.errorMessage) {
            return (
                <div className="alert alert-danger">
                    <em>Oops! {this.props.errorMessage}</em>
                </div>
            );
        }
    }

    render() {
        const { handleSubmit } = this.props;

        const handleClose = () => {
            history.push('/');
        };

        return (
            <form onSubmit={handleSubmit(this.handleFormSubmit.bind(this))}>
                <LoginWrapper>
                    <fieldset className="form-group">
                        <legend>Email</legend>
                        <Field className="form-control" name="email" component="input" type="text" />
                    </fieldset>
                    <fieldset className="form-group">
                        <legend>Password</legend>
                        <Field className="form-control" name="password" component="input" type="password" />
                    </fieldset>
                    {this.renderError()}
                    <div className='buttons'>
                        <button action="submit" className="btn btn-primary">Sign in</button>
                        <button className='close' onClick={() => handleClose()}>Cancel</button>
                    </div>
                </LoginWrapper>
            </form>
        );
    }
}

const LoginWrapper = styled.div`
    color: white;
    .buttons {
        margin-top: 0.5rem;;
        display: flex;
        justify-content: space-around;
    }
`;

const mapStateToProps = (state) => {
    return { errorMessage: state.auth.error }
};

export default reduxForm({
    form: 'signin'
})(connect(mapStateToProps, actions)(Login));
