import React from 'react'
import AppDetail from '../components/apps/app.detail'
import AppsPanel from '../components/apps/apps.panel'

const MyProjects = () => {
    const [selectedApp, setSelectedApp] = React.useState(null)

    const clicked = (app) => {
        console.log(app) 
            window.open(app.link)
    }

    console.log(selectedApp)

    return (
        <div>
            <a href='#widgets' style={{color: 'white'}}>Android Widgets</a>
            {!selectedApp && <AppsPanel select={clicked} />}
            {selectedApp && <AppDetail close={() => setSelectedApp(null)} app={selectedApp} />}
            <button id="widgets">widgets</button>
        </div>
    )
}

export default MyProjects