import React from 'react'
import styled from 'styled-components'
import PicsDisplay from '../components/pics/pics.display';
import PicsSplash from '../components/pics/pics.splash';

const Home = () => {
    const [showPics, setShowPics] = React.useState(false)

    return (
        <HomeWrapper>
            {/* <p>
                Welcome to my work in progress!
            </p> */}
            <div className='main'>
                <button className='control' onClick={() => setShowPics(!showPics)}>{showPics ? 'Hide pics' : 'A few random pics'}</button>
                {showPics && <PicsSplash />}
            </div>
        </HomeWrapper>
    )
}

const HomeWrapper = styled.div`
    padding: 1rem;
    min-height: 70vh;
    text-align: center;
    p {
        color: white;
        font-size: 1.5rem;
        text-shadow: 1px 1px 1px white, 2px 2px 2px white, 3px 3px 3px white;
    }
    .main {
        min-height: 25vh;
        .control {
            background-color: blue;
            color: white;
            border-radius: 2rem;
            padding: 0.5rem;
        }
    }
    .section-wrapper {
        display: flex;
        justify-content: space-around;
        .section {
            border: 1px solid blue;
            border-radius: 3rem;
            color: blue;
            background-color: aquamarine;
            text-shadow: 2px 2px 2px white;
            padding: 5rem;
            font-size: 2rem;
            :hover {
                background-color: aqua;
                cursor: pointer;
            }
        }
    }
`;

export default Home