import React from 'react'

const PicsItem = ({ pic, next }) => {
    
    const [rotation, setRotation] = React.useState(0)
    const [x, setX] = React.useState(0)
    const [y, setY] = React.useState(0)

    return (
        <img onClick={next} src={pic} style={{width: 400, height: 400, transform: `rotate(${rotation}deg)`, left: `${x}px`, top: `${y}px`}} />
    )
}

export default PicsItem
