import React from 'react'
import styled from 'styled-components'

import pic1 from '../../img/pics/1.jpg'
import pic2 from '../../img/pics/2.jpg'
import pic3 from '../../img/pics/3.png'
import pic4 from '../../img/pics/4.jpg'
import pic5 from '../../img/pics/5.png'
import pic6 from '../../img/pics/6.jpg'
import pic7 from '../../img/pics/7.jpg'
import pic8 from '../../img/pics/8.jpg'
import pic9 from '../../img/pics/9.jpg'
import pic10 from '../../img/pics/10.jpg'
import pic11 from '../../img/pics/11.gif'
import pic12 from '../../img/pics/12.jpg'
import PicsItem from './pics.item'

const PicsSplash = () => {
    const pics = [pic1, pic2, pic3, pic4, pic5, pic6, pic7, pic8, pic9, pic10, pic11, pic12]

    const [currentPic, setCurrentPic] = React.useState(0)
    const [paused, setPaused] = React.useState(0)
    const [reverse, setReverse] = React.useState(0)

    React.useEffect(() => {
        const timer = setInterval(() => {
            if (paused) return
            reverse ? prevPic() : nextPic()
        }, 3000)

        return () => clearInterval(timer)
    })

    const nextPic = () => {
        if (currentPic + 1 > 11)
            setCurrentPic(0)
        else
            setCurrentPic(currentPic + 1)
    }

    const prevPic = () => {
        if (currentPic - 1 < 0)
            setCurrentPic(11)
        else
            setCurrentPic(currentPic - 1)
    }

    return (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
            <div style={{width: '50%', display: 'flex', justifyContent: 'space-around', marginBottom: '2rem' }}>
                <Control onClick={() => prevPic()}>prev</Control>
                <Control onClick={() => setPaused(!paused)}>{paused ? 'play' : 'pause'}</Control>
                <Control onClick={() => setReverse(!reverse)}>{reverse ? 'forward' : 'reverse'}</Control>
                <Control onClick={() => nextPic()}>next</Control>
            </div>
            <PicsItem next={() => nextPic()} pic={pics[currentPic]} />
        </div>
    )
}

const Display = styled.div`
    width: 100%;
`

const Control = styled.button`
    background-color: blue;
    color: white;
    border-radius: 2rem;
    padding: 0.5rem;
`

export default PicsSplash
