import React from 'react'
import styled from 'styled-components'
import Login from '../components/auth/login'
import Signup from '../components/auth/signup'

class Auth extends React.PureComponent {

    render() {
        return (
            <AuthWrapper>
                <Login />
                <Signup />
            </AuthWrapper>
        )
    }
}

const AuthWrapper = styled.div`
    min-height: 70vh;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export default Auth
