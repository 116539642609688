import React from 'react'
import { Route } from 'react-router-dom';
import styled from 'styled-components'
import Nav from './components/nav/nav'
import Home from './pages/Home'
import MyProjects from './pages/MyProjects'
import MyResume from './pages/MyResume'
import MyCode from './pages/MyCode'
import Auth from './pages/Auth'

function App() {

  return (
    <AppWrapper>
      <Nav />
      <MainContent>
        <Route path='/' exact component={Home} />
        <Route path='/login' exact component={Auth} />
        <Route path='/mycode' exact component={MyCode} />
        <Route path='/myresume' exact component={MyResume} />
        <Route path='/myprojects' exact component={MyProjects} />
      </MainContent>
    </AppWrapper>
  );
}

const MainContent = styled.div`
    margin-top: 7rem;
`;

const AppWrapper = styled.div`
    width: 100%;
    height: 90vh;
    margin: auto;
    position: relative;
`;

export default App;
